import React from "react";
import { useTheme } from '@mui/material/styles';
import { 
    Button, Typography, Box, Grid
} from "@mui/material";
import helpers from "../../../Helpers/Helpers";
import { DeleteForeverOutlined, EditOutlined } from "@mui/icons-material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export default function UsersView(props) {
    const theme = useTheme();
    const columns = [
        { field: 'org', headerName: props.literals.organization, valueGetter: (params) => params.row?.org?.org_name, width: 150, headerClassName: 'header' },
        { field: 'name', headerName: props.literals.name, renderCell: (params) => (`${params.row.fname} ${params.row.lname}`), width: 250, headerClassName: 'header' },
        { field: 'email', headerName: props.literals.email, valueGetter: (params) => params.row?.email, width: 150, headerClassName: 'header' },
        { field: 'address', headerName: props.literals.address, valueGetter: (params) => params.row?.address, width: 150, headerClassName: 'header' },
        { field: 'phone_no', headerName: props.literals.phone_no, valueGetter: (params) => params.row?.mobile_no, width: 200, headerClassName: 'header' },
        { field: 'role', headerName: props.literals.role, valueGetter: (params) => (params.row.role === undefined) ? props.literals.user : params.row.role, width: 150, headerClassName: 'header'},
        { field: 'verified', headerName: props.literals.verified, valueGetter: (params) => (params.row.email_verified)? 'Yes' : 'No', width: 150, headerClassName: 'header'},
        {
            field: 'action',
            headerName: props.literals.action,
            width: 150,
            headerClassName: 'header',
            renderCell: (params) => (
              <>
                <Button 
                    type="button" 
                    disabled={helpers.abilityByModuleKey('users').can_delete == false}
                    onClick={()=>props.delItem(params.row._id)} 
                    sx={{color: '#bc0000', background: '#bc00002e', p: '2px', minWidth: 0, m: 1}}
                >
                    <DeleteForeverOutlined />
                </Button>
                <Button
                    type="button" 
                    disabled={helpers.abilityByModuleKey('users').can_edit == false}
                    onClick={()=>{props.onEdit(params.row)}}
                    sx={{color: '#027c92', background: '#027c924d', p: '2px', minWidth: 0, m: 1}}
                >
                    <EditOutlined/>
                </Button>
              </>
            )
          },
    ]

    return (
        <Grid container spacing={3} sx={{placeContent: "center", py: 2}}>
            <Grid item xs={6}>
                <Typography variant="subtitle1" color="primary" className="font-bold font-gray"> {props.literals.users}</Typography>
            </Grid>
            <Grid item xs={6} align="right">
                <Button 
                    type="button"
                    variant="contained"
                    color="primary"
                    sx={{minWidth:110}}
                    onClick={props.setOpenDrawer}
                    disabled={helpers.abilityByModuleKey('users').can_add == false}
                >
                    {props.literals.add} +
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Box
                    sx={{
                    height: '75vh',
                    width: '100%',
                    '& .header': {
                        backgroundColor: theme.palette.primary.main,
                        color: '#fff'
                    },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row._id}
                        rows={props.users }
                        columns={columns}
                        disableSelectionOnClick={true}
                        components={{ Toolbar: GridToolbar }} 
                        density={'compact'}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}
