import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SnackAlert from "../../../shared/SnackAlert";
import Spinner from "../../../shared/Spinner";
import ParkingsView from "./ParkingsView";
import parkingServices from "../../../services/parking-service";
import moment from 'moment';

export default function ParkingsUtils(props) {
  let { id } = useParams();
  const PAGE_CONFIG = { pageSize: 25, page: 0 };
  const [spinner, setSpinner] = useState(false);
  const [msg, setMsg] = useState('');
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState('');
  const [inputField, setInputField] = useState({});
  const [parking, setParking] = useState({ data: [], pagination: { ...PAGE_CONFIG, total: 0 } });
  const [searched, setSearched] = useState("");
  const [rows, setRows] = useState([]);
  const [paramFilter, setParamFilter] = useState({});
  const [pagination, setPagintion] = useState(PAGE_CONFIG)
  const [filter, setFilter] = useState({})
  const [sort, setSort] = useState({ sortBy: '_id', sort: -1 })

  useEffect(() => {
    getFilter();
  }, [id])

  const getFilter = async () => {
    let body = {};
    if (id === 'current') {
      body = {
        from: { $lte: new Date() },
        to: { $gte: new Date() }
      }
    } else if (id === 'all') {
      body = {}
    } else if (id === 'paid') {
      body = {
        amount: { $ne: '0' }
      }
    } else if (id === 'free') {
      body = {
        amount: '0'
      }
    }
    setParamFilter(body);
    getParkings(body, { ...PAGE_CONFIG, ...sort });
  }

  const convertObjectToQueryString = (obj) => {
    return `?${Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join("&")}`;
  }

  const getParkings = async (e, query) => {
    setSpinner(true);
    const queryString = convertObjectToQueryString(query);
    const res = await parkingServices.getParkings({ ...e, ...{ org: props.org._id }}, queryString);
    setParking(res.data);
    setRows(res.data);
    setSpinner(false);
  }

  const onFilter = (obj) => {
    setFilter(obj);
    let queryObj = {
      ...pagination,
      ...sort,
      ...obj,
    };
    getParkings(paramFilter, queryObj);
  }

  const onPageChange = (obj) => {
    setPagintion(obj);
    let queryObj = {
      ...filter,
      ...sort,
      ...obj,
    };
    getParkings(paramFilter, queryObj);
  }

  const onSort = (obj) => {
    setSort(obj);
    let queryObj = {
      ...filter,
      ...pagination,
      ...obj,
    };
    getParkings(paramFilter, queryObj);
  }

  const requestSearch = (searchedVal) => {
    const filteredRows = rows.filter((row) => {
      return row.zone?.zone_name.toLowerCase().includes(searchedVal.toLowerCase())
        || row.parking_id.toString().includes(searchedVal)
        || row.city?.city_name.toLowerCase().includes(searchedVal.toLowerCase())
        || row.user?.fname.toLowerCase().includes(searchedVal.toLowerCase())
        || row.user?.email.toLowerCase().includes(searchedVal.toLowerCase())
        || row.plate.toLowerCase().includes(searchedVal.toLowerCase())
        || ((row.amount / 100).toFixed(2)).toString().toLowerCase().includes(searchedVal.toLowerCase())
        || (moment(row.from).format('ll hh:mm a')).toLowerCase().includes(searchedVal.toLowerCase())
        || (moment(row.to).format('ll hh:mm a')).toLowerCase().includes(searchedVal.toLowerCase())
    });
    setSearched(searchedVal);
    setParking(filteredRows);
  };

  const handleParkings = (e) => {
    console.log(paramFilter);
    let body = paramFilter;
    body["parking_type"] = e;
    getParkings(body);
  }

  return (
    <>
      <ParkingsView
        inputField={inputField}
        parking={parking}
        searched={searched}
        literals={props.literals}
        org_id={props.org._id}

        onPageChange={onPageChange}
        onFilter={onFilter}
        onSort={onSort}
        requestSearch={(e) => requestSearch(e)}
        handleParkings={(e) => handleParkings(e)}
        setSpinner={(e) => setSpinner(e)}
      />
      <SnackAlert
        msg={msg}
        alert={alert}
        severity={severity}

        closeAlert={() => setAlert(!alert)}
      />
      <Spinner
        spinner={spinner}
      />
    </>
  );
}
