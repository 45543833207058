import React from "react";
import { alpha, styled, useTheme } from '@mui/material/styles';
import { 
    Button, IconButton, InputBase, Grid, Typography, Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DeleteForeverOutlined, Download, EditOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import helpers from "../../../Helpers/Helpers";
import { config } from "../../../Constants";
import QRCode from "qrcode.react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


export default function ZonesView(props) {
    const theme = useTheme();
    const columns = [
      { field: 'org', headerName: props.literals.organization, valueGetter: (params) => params.row?.org?.org_name, width: 150, headerClassName: 'header' },
      { field: 'zone', headerName: props.literals.zone, valueGetter: (params) => params.row?.zone_name, width: 250, headerClassName: 'header' },
      { field: 'city', headerName: props.literals.city, valueGetter: (params) => params.row?.city_id?.city_name, width: 150, headerClassName: 'header' },
      { field: 'tenant_zone', headerName: props.literals.tenant_zone, valueGetter: (params) => params.row?.tenant_zone === true ? 'Yes' : 'No', width: 150, headerClassName: 'header' },
      {
        field: 'link',
        headerName: props.literals.link,
        width: 300,
        headerClassName: 'header',
        renderCell: (params) => {
          let link = (params?.row.org?.sub_domain == 'root') ? 
            config.url.http+config.url.client_url+'zone/'+params?.row._id : 
            config.url.http+params?.row.org?.sub_domain+'.'+config.url.client_url+'zone/'+params?.row._id;
          return(
          <>
            <a href={link} target="_blank">{link}</a>
          </>
        )}
      },
      {
        field: 'qr_code',
        headerName: props.literals.qr_code,
        width: 150,
        headerClassName: 'header',
        renderCell: (params) => {
          let link = (params?.row.org?.sub_domain == 'root') ? 
            config.url.http+config.url.client_url+'zone/'+params?.row._id : 
            config.url.http+params?.row.org?.sub_domain+'.'+config.url.client_url+'zone/'+params?.row._id;
          return(
          <>
            <IconButton color="primary" onClick={()=>downloadQRCode(params?.row)}>
              <Download />
            </IconButton>
            <div style={{display: 'none'}}>
              <QRCode
                id={params?.row._id}
                value={link}
                size={400}
                level={"H"}
                includeMargin={true}
              />
            </div>
          </>
        )}
      },
      {
        field: 'amount',
        headerName: props.literals.action,
        width: 150,
        headerClassName: 'header',
        renderCell: (params) => (
          <>
            <Button
              type="button"
              disabled={helpers.abilityByModuleKey('zones').can_edit == false}
              onClick={()=>{props.onEdit(params?.row);}}
              sx={{color: '#027c92', background: '#027c924d', p: '2px', minWidth: 0, m: 1}}
            >
                <EditOutlined/>
            </Button>
            <Button 
                type="button" 
                disabled={helpers.abilityByModuleKey('zones').can_delete == false}
                onClick={()=>props.delItem(params?.row._id)} 
                sx={{color: '#bc0000', background: '#bc00002e', p: '2px', minWidth: 0, m: 1}}
            >
                <DeleteForeverOutlined />
            </Button>
          </>
        )
      },
    ]

    const downloadQRCode = (e) => {
      // Generate download with use canvas and stream
      const canvas = document.getElementById(e._id);
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${e.org?.org_name}-${e.zone_name}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    
    return (
      <Grid container spacing={3} sx={{placeContent: "center", py: 2}}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" color="primary" className="font-bold font-gray">{props.literals.zones}</Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Button 
              type="button"
              variant="contained"
              color="primary"
              sx={{minWidth:110}}
              onClick={props.setOpenDrawer}
              disabled={helpers.abilityByModuleKey('zones').can_add == false}
          >
            {props.literals.add} +
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              height: '75vh',
              width: '100%',
              '& .header': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff'
              },
            }}
          >
            <DataGrid
              getRowId={(row) => row._id}
              rows={props.zones }
              columns={columns}
              disableSelectionOnClick={true}
              components={{ Toolbar: GridToolbar }} 
              density={'compact'}
            />
          </Box>
        </Grid>
      </Grid>
    );
}
