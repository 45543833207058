import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import moment from 'moment';
import CustomTable from "../../../shared/CustomTable";
import organizationServices from "../../../services/organization-service";
import cityServices from "../../../services/city-service";

export default function ParkingsView(props) {
  const user = JSON.parse(sessionStorage.getItem('userLogged'));
  const [organizations, setOrganizations] = useState([]);
  const [cities, setCities] = useState([]);
  const [zones, setZones] = useState([]);

  const columns = [
    { field: 'parking_id', headerName: 'Parking ID', type: 'number', filterable: true},
    { field: 'org', headerName: 'Organization', valueFormatter: (params) => params?.value?.org_name, minWidth: 150, type: "singleSelect",
    valueOptions: organizations, filterable: true},
    { field: 'zone', headerName: 'Zone', valueFormatter: (params) => params?.value?.zone_name, minWidth: 200, type: "singleSelect",
    valueOptions: zones, filterable: true},
    { field: 'city', headerName: 'City', valueFormatter: (params) => params.value?.city_name, minWidth: 150, type: "singleSelect",
    valueOptions: cities, filterable: true},
    { field: 'fname', headerName: 'User Name', valueFormatter: (params) => params.value?.fname, minWidth: 150, filterable: false},
    { field: 'email', headerName: 'User Email', valueFormatter: (params) => params.value?.email, minWidth: 200, filterable: false},
    { field: 'plate', headerName: 'Plate', minWidth: 150, filterable: true},
    { field: 'amount', headerName: 'Amount', type: 'number', valueFormatter: (params) => '$ '+(params.value/100).toFixed(2), minWidth: 150, filterable: true},
    { field: 'from', headerName: 'Start Date/Time', type: 'date', valueFormatter: (params) => moment(params?.value).format('ll hh:mm a'), minWidth: 200, filterable: true},
    { field: 'to', headerName: 'End Date/Time', type: 'date', valueFormatter: (params) => moment(params?.value).format('ll hh:mm a'), minWidth: 200, filterable: true},
  ]
  
  useEffect(()=>{
    getOrganizations();
    getCities();
    getZones();
  },[])

  const getOrganizations = async()=>{
    props.setSpinner(true);
    const res = await organizationServices.getOrganizations();
    let org;
    if(user.result?.role !== 'root'){
      org = res.data.find(x=>x._id == props.org_id);
    }else{
      org = res.data;
    }
    setOrganizations(org.map(x => ({value: x._id, label: x.org_name})));
    props.setSpinner(false);
  }

  const getCities = async()=>{
    props.setSpinner(true);
    const res = await cityServices.getCities({org_id: props.org_id});
    setCities(res.data.map(x => ({value: x._id, label: x.city_name})))
    props.setSpinner(false);
  }

  const getZones = async()=>{
    props.setSpinner(true);
    const res = await cityServices.getZones({org_id: props.org_id});
    setZones(res.data.map(x => ({value: x._id, label: x.zone_name})))
    props.setSpinner(false);
  }

  return (
    <Grid container spacing={3} sx={{placeContent: "center"}}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="primary">{props.literals.parkings}</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={columns}
          data={props.parking}

          onPageChange={props.onPageChange}
          onFilter={props.onFilter}
          onSort={props.onSort}
          onEdit={props.onEdit}
          delItem={props.delItem}
      />
      </Grid>
    </Grid>
  );
}
